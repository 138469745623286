<template>
  <div class="page">
    <Navbar mode="solid" class="fixed top-0 left-0"/>
    <main class="page-content">
      <div class="page-container">
        <h2 class="page-title">
          {{ $t("resetpass_page.title") }}
        </h2>
        <EmptyState v-if="showConfirmation"
          icon-class="bx bx-check"
          :title="$t('resetpassword.confirmation.title')"
          :text="$t('resetpassword.confirmation.description')"
          icon-color="green"
          :use-link="true"
          link-path="/"
          :btn-text="$t('cta.go_to_homepage')"
        />
        <form v-else
            id="resetpassword"
            method="post"
            @submit.prevent="resetAction"
            action=""
            novalidate="true"
        >
          <InputText
              :label="$t('resetpassword.input_1.label')"
              :placeholder="$t('resetpassword.input_1.placeholder')"
              type="email"
              name="email"
              :errorMsg="emailErrMsg"
              :showErrMsg="$v.email.$error"
              @blur="$v.email.$touch()"
              v-model="email"
              class="mb-4"
          />

          <router-link class="btn-link ml-auto" to="/login">
            {{ $t("resetpassword.button_1") }}
          </router-link>

          <ButtonPrimary
              type="submit"
              class="w-full"
              :text="$t('resetpassword.button_2')"
              :loading="loading"
          />

          <div class="flex mt-6 font-sans text-sm">
            <span v-if="showSignup" class="text-sm text-black-base">
              {{ this.$t("resetpassword.text") }}
            </span>
            <router-link 
                class="btn-link ml-2" :to="`/signup?lang=${lang}`">
              {{ $t("resetpassword.button_3") }}
            </router-link >
          </div>
        </form>
      </div>

    </main>
    <BottomBar class="layout-bar"/>
  </div>
</template>

<script>
import BottomBar from "@/components/layout/BottomBar";
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import InputText from "@/components/inputs/InputText";
import {mapActions } from "vuex";
import {email, required} from "vuelidate/lib/validators";
import EmptyState from "@/components/EmptyState.vue";

export default {
  components: {
    BottomBar,
    ButtonPrimary,
    InputText,
    EmptyState
  },
  name: "PasswordReset",
   data() {
    return {
      email: '',
      loading: false,
      showConfirmation: false,
      lang: localStorage.getItem("lang") || "en",
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  props: {
    showSignup: {type: Boolean, default: true},
  },
  computed: {
    emailErrMsg() {
      let msg;
      if (!this.$v.email.email) {
        msg = this.$t("resetpassword.input_1.error_1");
      }
      if (!this.$v.email.required) {
        msg = this.$t("resetpassword.input_1.error_2");
      }
      return msg;
    },
  },
  methods: {
    ...mapActions('auth', ['forgotPassword']),
    async resetAction() {
      try {
        this.loading = true;
        this.$v.email.$touch();
        if (this.$v.$invalid) {
          return false;
        }

        const res = await this.forgotPassword({email: this.email});
        this.loading = false;
        if (!res) return false;
        this.showConfirmation = true;
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: 'error',
          text: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<style scoped>
.page-content {
  @apply px-4 py-12 md:px-12 mt-20;
}

.page-container {
  @apply w-full max-w-md;
  @apply relative transform -translate-x-1/2 left-1/2;
}

.page-title {
  @apply font-sans text-2xl font-semibold text-black-base;
  @apply mb-8;
}

.login-input {
  @apply mb-4;
}

.layout-bar {
  @apply fixed bottom-0 w-full sm:hidden;
}

.btn-link {
  @apply font-sans font-semibold text-right text-sm text-teal-dark hover:text-teal-base;
  @apply block mb-6;
  @apply transition duration-300 ease-in-out;
}

#submit {
  @apply w-full;
}
</style>